import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'sliderUrl',
  standalone: true
})
export class SliderUrlPipe implements PipeTransform {

  transform(value: string, ...args: unknown[]): unknown {
    const http = 'http://'
    const https = 'https://'
    const prefix = 'FILESTORE/AppSettingsSiteLogos/';
    if(value.includes(http) || value.includes(https)){
      return value.replace(prefix,'');
    }
    return https+value.replace(prefix,'');
  }

}
